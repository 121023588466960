.entity-st-event.view-mode-search_results {
  background-color: white;
  box-shadow: 0 0 7px rgba(black, 0.1);
  text-align: left;

  .group-content {
    padding: 20px;
  }
  .event-name {
    color: $blue;
  }
  .event-sport {
    color: $blue;
    margin-bottom: 10px;
  }
  .field--name-field-event-date {
    font-size: 18px;
  }
  .view-button {
    background-color: lighten($certificate-gray, 20%);
    color: white;
    display: block;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    &:hover {
      background-color: $blue;
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .entity-st-event.view-mode-search_results {

  }
}
