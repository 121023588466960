.block--bean-numbers {
  padding: 60px 20px;

  h2 {
    padding: 0 0 50px 0;
  }
  .block-inner-wrap {
    @include container(1000px);
  }
  .field--name-field-block-body {
    text-align: center;
  }
  .field--name-field-image {
    margin-top: 60px;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block--bean-numbers {
    padding: 80px 20px;

    h2 {
      font-size: 30px;
      padding: 50px 0 50px 0;
      text-align: right;
    }
    .group-left {
      box-sizing: border-box;
      padding-right: 50px;
      padding-top: 40px;
    }
    .field--name-field-block-body {
      text-align: right;
    }
    .field--name-field-image {
      margin-top: 0;
      position: relative;

      &:after {
        background: url("../images/bg-numbers.png") no-repeat;
        content: "";
        display: block;
        height: 509px;
        position: absolute;
        right: -80px;
        top: -80px;
        width: 702px;
        z-index: -1;
      }
    }
  }
}
