.node-type-service {
  .breadcrumb {
    display: none;
  }
  .block--cool-tr-blocks-page-title {
    padding: 0;
    position: absolute;
    text-align: center;
    margin-top: 120px;
    width: 100%;
    z-index: 1;

    h1 {
      color: $light-gray;
      font-size: 36px;
      line-height: 40px;
      padding: 0 20px;
    }
    &:before {
      border-top: thin dashed $light-gray;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      margin-left: -50px;
      position: absolute;
      top: -50px;
      width: 100px;
    }
  }
  .group-header {
    background: url("../images/triangles.png");
    height: 300px;
    position: relative;

    .field--name-field-image {
      bottom: -48px;
      left: 50%;
      margin-left: -48px;
      position: absolute;

      .field__item {
        background-color: rgba(white, 0.05);
        border-radius: 48px;
        display: block;
        height: 78px;
        padding: 9px;
        width: 78px;
      }
    }
  }
  .node--service {
    text-align: center;

    .field--name-body {
      padding: 70px 20px 40px 20px;
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .node-type-service {
    .block--cool-tr-blocks-page-title {
      margin-top: 140px;

      h1 {
        font-size: 48px;
        line-height: 50px;
      }
      &:before {
        margin-left: -108px;
        width: 216px;
      }
    }
    .node--service {

      .field--name-body {
        font-size: 20px;
        line-height: 24px;
        max-width: 600px;
        margin: 0 auto;
        padding: 120px 20px 100px 20px;

        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}
