.page-results .block--cool-tr-blocks-page-title {
  margin-top: 40px;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2;

  h1 {
    color: white;
    font-size: 48px;
    line-height: 55px;
    text-shadow: 2px 2px 5px rgba(black, 0.60)
  }
}

.block--views-exp-events-page {
  background: url('../images/bg-results-search.jpg') no-repeat top center;
  box-sizing: border-box;
  color: white;
  height: 360px;
  padding: 90px 0 0 0;
  text-align: center;

  .page-description {
    font-size: 20px;
    line-height: 22px;
    padding: 20px;
  }
  .views-exposed-widget {
    float: none;
    padding: 0;
  }
  .form-item-sport {
    select {
      width: 200px;
    }
  }
  .views-widget-filter-name {
    margin-top: 20px;
    padding: 0 16px;
  }
}

.view-events.view-display-id-page {
  background-image: url("../images/shattered.jpg");
  text-align: center;

  .view-empty {
    font-size: 20px;
    padding: 20px;
  }
  .views-row-last {
    margin-bottom: 0;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .page-results .block--cool-tr-blocks-page-title {
    margin-top: 90px;
  }
  .block--views-exp-events-page {
    padding: 140px 0 0 0;

    .views-exposed-widgets {
      display: inline-block;
    }
    .views-exposed-widget {
      float: left;
    }
    .views-widget-filter-name {
      padding: 0;
      margin: 0 0 0 20px;
    }
  }
  .view-events.view-display-id-page .view-content {
    @include container;
  }
}
