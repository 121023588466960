.block-home-main {
  background-image: url("../images/bg-bikes-big.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  padding: 50px 20px;
  text-align: center;
  h2.block__title {
    color: $blue;
    font-family: 'Carter One', cursive;
    font-size: 30px;
    padding: 50px 0;
    text-shadow: 2px 2px 3px rgba(black, 0.3);
    span {
      color: white;
      margin-left: -5px;
    }
  }
  .block-inner-wrap {
    @include container(700px);
  }
  .block__content {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    text-shadow: 2px 2px 3px rgba(black, 0.3);
    p {
      padding: 20px 0;
    }
  }
  .buttons {
    margin-top: 20px;
    text-shadow: none;

    .link-button {
      font-size: 20px;
      margin-bottom: 20px;
      &.last-button {
        margin-bottom: 0;
      }
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block-home-main {
    padding: 100px 20px 60px 20px;

    h2.block__title {
      font-size: 48px;
      span {
        margin-left: -8px;
      }
    }
    .block__content {
      font-size: 24px;
      line-height: 30px;
    }
    .buttons {
      .link-button {
        margin: 0 10px;
      }
    }
  }
}
