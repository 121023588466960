.block {
  padding: 20px;

  h2 {
    padding: 50px 0 70px 0;
    position: relative;
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block.title-line {
    h2 {

      &:before,
      &:after {
        background-color: $mercury;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        top: 65px;
      }
      &:before {
        left: 20px;
      }
      &:after {
        right: 20px;
      }
    }
  }
}
