.block--views-what-we-do-block {
  background-image: url("../images/shattered.jpg");
  padding-bottom: 80px;

  .block-inner-wrap {
    @include container;
  }
  h2.block__title {
    &:before,
    &:after {
      width: 35%;
    }
  }
  .view {
    display: inline-block;

    .views-row {
      margin-bottom: $vert-spacing-unit;
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block--views-what-we-do-block {
    .view {
      .views-row {
        @include span(6 of $tab-columns);
      }
      .views-row-even {
        @include span(6 of $tab-columns last);
      }
    }
  }
}
