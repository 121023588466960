@charset "UTF-8";
.ajax-progress {
  background-color: #249fe2;
  border: 2px solid white;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 10; }
  .ajax-progress .throbber {
    background: url("../images/loader.gif");
    margin: 10px;
    height: 60px;
    width: 60px; }

@media (min-width: 44em) {
  .ajax-progress {
    right: 40px;
    top: 40px; } }

body {
  background-repeat: no-repeat;
  color: #333333;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px; }

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400; }

h1 {
  color: #249fe2;
  font-size: 24px;
  line-height: 30px; }

h2 {
  font-size: 24px;
  line-height: 30px; }

p {
  padding-top: 5px;
  padding-bottom: 5px; }

img {
  height: auto;
  max-width: 100%; }

a {
  color: #249fe2;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-decoration: none; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  /* fix buttons height */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */ }

.icon-plus {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#x2b;&nbsp;'); }

.icon-award {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe801;&nbsp;'); }

.icon-user {
  *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe807;&nbsp;'); }

@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?14989335");
  src: url("../font/fontello.eot?14989335#iefix") format("embedded-opentype"), url("../font/fontello.woff?14989335") format("woff"), url("../font/fontello.ttf?14989335") format("truetype"), url("../font/fontello.svg?14989335#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?14989367#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-plus:before {
  content: '\2b'; }

/* '+' */
.icon-award:before {
  content: '\e801'; }

/* '' */
.icon-user:before {
  content: '\e807'; }

/* '' */
input {
  font-family: 'Lato', sans-serif;
  font-weight: 300; }

input.form-text {
  border: thin solid #249fe2;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  width: 100%; }
  input.form-text.white-border {
    color: white;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.6);
    border: 3px solid white;
    font-size: 22px; }
  input.form-text.error {
    border: thin solid #d94f13; }
  @media (min-width: 44em) {
    input.form-text {
      width: auto;
      max-width: 100%; } }

input:focus {
  outline: 0; }

select {
  cursor: pointer;
  z-index: 2; }

.customSelect {
  height: 50px;
  position: relative;
  z-index: 1; }
  .customSelect .customSelectInner {
    background-color: #249fe2;
    background-image: url("../images/select-arrow.png");
    background-repeat: no-repeat;
    background-position: center right;
    box-sizing: border-box;
    color: white;
    padding: 14px 50px 14px 14px; }
  .customSelect.error .customSelectInner {
    background-color: #d94f13; }
  .customSelect.white-border {
    height: 60px; }
    .customSelect.white-border .customSelectInner {
      background-color: rgba(0, 0, 0, 0.6);
      border: 3px solid white;
      font-size: 22px;
      padding: 17px 52px 17px 15px; }

.link-button,
input.form-submit {
  background-color: #249fe2;
  border: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 40px;
  width: 100%; }
  .link-button:hover,
  input.form-submit:hover {
    background-color: #13628d; }
  @media (min-width: 44em) {
    .link-button,
    input.form-submit {
      width: auto; } }

html.js input.form-autocomplete {
  background: url("../images/throbber-inactive.png") no-repeat scroll 98% 50% transparent; }

html.js input.form-autocomplete.throbbing {
  background: url("../images/throbber-active.gif") no-repeat scroll 98% 50% transparent; }

.l-content {
  min-height: 400px; }

.tr-boxes-view {
  padding: 0 20px 20px 20px; }
  .tr-boxes-view .masonry-column {
    width: 100%; }
  .tr-boxes-view .views-row {
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    margin: 20px 0 0 0;
    padding: 0;
    width: 100%; }

/**
 * Tablets.
 */
@media (min-width: 44em) {
  body {
    background-repeat: repeat; }
  /**
   * Boxes views.
   */
  .tr-boxes-view {
    padding: 0 1.69492% 1.69492% 1.69492%; }
    .tr-boxes-view .views-row,
    .tr-boxes-view .masonry-column {
      width: 49.15254%; }
    .tr-boxes-view .views-row {
      margin: 1.69492% 0 0 0; }
    .tr-boxes-view .masonry-gutter {
      width: 1.69492%; } }

/**
 * Desktops.
 */
@media (min-width: 70em) {
  body {
    background-repeat: repeat-x; }
  /**
   * Boxes views.
   */
  .tr-boxes-view .views-row,
  .tr-boxes-view .masonry-column {
    width: 32.20339%; } }

ul.tr-action-links {
  text-transform: capitalize; }
  ul.tr-action-links li {
    float: left;
    margin-right: 20px; }
    ul.tr-action-links li a {
      padding: 10px 20px;
      border: thin solid #249fe2;
      border-radius: 3px; }
    ul.tr-action-links li a:hover {
      background-color: #249fe2;
      color: white; }

table {
  text-align: center;
  width: 100%; }
  table th {
    background-color: #249fe2;
    color: white;
    height: 45px;
    font-size: 18px;
    vertical-align: middle;
    text-align: center; }
  table tr.even {
    background-color: #F4F4F4; }
  table tr.odd {
    background-color: white; }
  table td {
    height: 35px;
    vertical-align: middle; }
  table caption {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 26px; }
    table caption::after, table caption::before {
      color: #CCCCCC;
      content: "-----------------";
      font-weight: 300;
      letter-spacing: -2px; }
    table caption::after {
      margin-left: 20px; }
    table caption::before {
      margin-right: 20px; }

.bean-text-with-image p {
  margin-bottom: 15px; }

@media (min-width: 44em) {
  .bean-text-with-image .group-left {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .bean-text-with-image .group-right {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

.block {
  padding: 20px; }
  .block h2 {
    padding: 50px 0 70px 0;
    position: relative;
    text-align: center; }

@media (min-width: 44em) {
  .block.title-line h2:before, .block.title-line h2:after {
    background-color: #e4e4e4;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 65px; }
  .block.title-line h2:before {
    left: 20px; }
  .block.title-line h2:after {
    right: 20px; } }

.contact-form {
  text-align: center; }
  .contact-form .block-inner-wrap {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
    .contact-form .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .contact-form .webform-component--contact-me-at {
    text-align: center; }
    .contact-form .webform-component--contact-me-at label {
      margin-bottom: 20px;
      margin-top: 20px; }
    .contact-form .webform-component--contact-me-at select {
      min-width: 200px; }
  .contact-form .form-actions {
    border-top: thin solid #e4e4e4;
    margin-top: 20px;
    padding-top: 20px;
    text-align: center; }

@media (min-width: 44em) {
  .contact-form .webform-component--name {
    width: 40.67797%;
    float: left;
    margin-right: 1.69492%; }
  .contact-form .webform-component--email {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .contact-form .webform-component--phone {
    width: 23.72881%;
    float: right;
    margin-right: 0; }
  .contact-form .webform-component--contact-me-at {
    display: inline-block; }
    .contact-form .webform-component--contact-me-at label {
      float: left;
      margin-bottom: 14px;
      margin-right: 20px;
      margin-top: 14px; }
  .contact-form .form-actions {
    margin-top: 70px;
    padding-top: 0; }
    .contact-form .form-actions input {
      border-left: 20px solid white;
      border-right: 20px solid white;
      position: relative;
      top: -30px; } }

.block--bean-contact-phone {
  background-color: #249fe2;
  text-align: center; }
  .block--bean-contact-phone .field--name-field-block-body span {
    display: block;
    font-size: 24px;
    width: 100%; }
  .block--bean-contact-phone .field--name-field-block-body a {
    background-color: #7ec6ee;
    border-radius: 20px;
    color: #1672a4;
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    text-decoration: none; }
  .block--bean-contact-phone .field--name-field-block-body a:hover {
    background-color: #abdaf4; }

@media (min-width: 44em) {
  .block--bean-contact-phone .field--name-field-block-body {
    display: inline-block;
    font-size: 22px; }
    .block--bean-contact-phone .field--name-field-block-body .left {
      border-right: thin dashed white;
      float: left;
      height: 21px;
      margin-top: 12px;
      padding-right: 12px; }
    .block--bean-contact-phone .field--name-field-block-body span {
      display: inline;
      font-size: 34px;
      width: auto; }
    .block--bean-contact-phone .field--name-field-block-body a {
      font-size: 18px;
      margin-top: 0;
      margin-left: 12px; }
  .front .block--bean-contact-phone .field--name-field-block-body .left {
    border-right: none;
    padding-right: 0; }
  .front .block--bean-contact-phone .field--name-field-block-body a {
    display: none; } }

.block-home-main {
  background-image: url("../images/bg-bikes-big.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  padding: 50px 20px;
  text-align: center; }
  .block-home-main h2.block__title {
    color: #249fe2;
    font-family: 'Carter One', cursive;
    font-size: 30px;
    padding: 50px 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3); }
    .block-home-main h2.block__title span {
      color: white;
      margin-left: -5px; }
  .block-home-main .block-inner-wrap {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; }
    .block-home-main .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block-home-main .block__content {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3); }
    .block-home-main .block__content p {
      padding: 20px 0; }
  .block-home-main .buttons {
    margin-top: 20px;
    text-shadow: none; }
    .block-home-main .buttons .link-button {
      font-size: 20px;
      margin-bottom: 20px; }
      .block-home-main .buttons .link-button.last-button {
        margin-bottom: 0; }

@media (min-width: 44em) {
  .block-home-main {
    padding: 100px 20px 60px 20px; }
    .block-home-main h2.block__title {
      font-size: 48px; }
      .block-home-main h2.block__title span {
        margin-left: -8px; }
    .block-home-main .block__content {
      font-size: 24px;
      line-height: 30px; }
    .block-home-main .buttons .link-button {
      margin: 0 10px; } }

.block--bean-huge-experience {
  background-image: url("../images/bg-blizzard.png");
  padding: 60px 20px; }
  .block--bean-huge-experience h2 {
    padding-bottom: 50px; }
  .block--bean-huge-experience .block-inner-wrap {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .block--bean-huge-experience .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block--bean-huge-experience .field--name-field-block-body {
    text-align: center; }

@media (min-width: 44em) {
  .block--bean-huge-experience {
    padding: 80px 20px; }
    .block--bean-huge-experience h2 {
      font-size: 20px;
      text-align: left;
      width: 70%; }
    .block--bean-huge-experience .group-right {
      padding-top: 40px; }
    .block--bean-huge-experience .field--name-field-block-body {
      text-align: left; } }

.block--bean-numbers {
  padding: 60px 20px; }
  .block--bean-numbers h2 {
    padding: 0 0 50px 0; }
  .block--bean-numbers .block-inner-wrap {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }
    .block--bean-numbers .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block--bean-numbers .field--name-field-block-body {
    text-align: center; }
  .block--bean-numbers .field--name-field-image {
    margin-top: 60px; }

@media (min-width: 44em) {
  .block--bean-numbers {
    padding: 80px 20px; }
    .block--bean-numbers h2 {
      font-size: 30px;
      padding: 50px 0 50px 0;
      text-align: right; }
    .block--bean-numbers .group-left {
      box-sizing: border-box;
      padding-right: 50px;
      padding-top: 40px; }
    .block--bean-numbers .field--name-field-block-body {
      text-align: right; }
    .block--bean-numbers .field--name-field-image {
      margin-top: 0;
      position: relative; }
      .block--bean-numbers .field--name-field-image:after {
        background: url("../images/bg-numbers.png") no-repeat;
        content: "";
        display: block;
        height: 509px;
        position: absolute;
        right: -80px;
        top: -80px;
        width: 702px;
        z-index: -1; } }

.block-one-team {
  background-color: #249fe2;
  color: white; }
  .block-one-team .block__content {
    font-size: 20px;
    line-height: 24px;
    text-align: center; }

@media (min-width: 44em) {
  .block-one-team {
    background-image: url("../images/bg-one-team.jpg");
    background-position: center center; }
    .block-one-team .block__content {
      background-color: rgba(36, 159, 226, 0.8);
      font-size: 24px;
      line-height: 30px;
      max-width: 700px;
      margin: 200px auto;
      padding: 40px; } }

.front .block--cool-tr-blocks-page-title {
  padding: 0; }

.block--cool-tr-blocks-page-title {
  text-align: center; }

.block--bean-register-wherever {
  background-image: url("../images/bg-blizzard.png");
  padding: 60px 20px; }
  .block--bean-register-wherever h2 {
    padding-bottom: 50px; }
  .block--bean-register-wherever .block-inner-wrap {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .block--bean-register-wherever .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block--bean-register-wherever .field--name-field-block-body {
    text-align: center; }

@media (min-width: 44em) {
  .block--bean-register-wherever h2 {
    font-size: 20px;
    text-align: left;
    width: 70%; }
  .block--bean-register-wherever .group-right {
    padding-top: 60px; }
  .block--bean-register-wherever .field--name-field-block-body {
    text-align: left; } }

.block--bean-results-wherever {
  background-image: url("../images/bg-diagonal-blue.png");
  color: white;
  padding: 60px 20px; }
  .block--bean-results-wherever h2 {
    padding: 0 0 50px 0; }
  .block--bean-results-wherever .block-inner-wrap {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }
    .block--bean-results-wherever .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block--bean-results-wherever .field--name-field-block-body {
    text-align: center; }
  .block--bean-results-wherever .field--name-field-image {
    margin-top: 60px; }

@media (min-width: 44em) {
  .block--bean-results-wherever h2 {
    font-size: 30px;
    padding: 50px 0 50px 0;
    text-align: right; }
  .block--bean-results-wherever .group-left {
    box-sizing: border-box;
    padding-right: 50px;
    padding-top: 60px; }
  .block--bean-results-wherever .field--name-field-block-body {
    text-align: right; }
  .block--bean-results-wherever .field--name-field-image {
    margin-top: 0; } }

.block--tr-blocks-search-by-number h2 {
  padding: 20px 0; }

.block--tr-blocks-search-by-number .block-inner-wrap {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }
  .block--tr-blocks-search-by-number .block-inner-wrap:after {
    content: " ";
    display: block;
    clear: both; }

@media (min-width: 44em) {
  .block--tr-blocks-search-by-number .form-item-event {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
    .block--tr-blocks-search-by-number .form-item-event input {
      width: 100%; }
  .block--tr-blocks-search-by-number .form-item-number {
    width: 32.20339%;
    float: right;
    margin-right: 0; }
  .block--tr-blocks-search-by-number .form-actions {
    border-top: thin solid #e4e4e4;
    float: left;
    height: 40px;
    margin-top: 40px;
    padding-top: 0;
    text-align: center;
    width: 100%; }
    .block--tr-blocks-search-by-number .form-actions input {
      border-left: 20px solid white;
      border-right: 20px solid white;
      position: relative;
      top: -30px; } }

.block--bean-wheater-endures {
  background-image: url("../images/bg-halftone.png");
  padding: 60px 20px; }
  .block--bean-wheater-endures h2 {
    padding-bottom: 50px; }
  .block--bean-wheater-endures .block-inner-wrap {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }
    .block--bean-wheater-endures .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block--bean-wheater-endures .field--name-field-block-body {
    text-align: center; }

@media (min-width: 44em) {
  .block--bean-wheater-endures {
    position: relative; }
    .block--bean-wheater-endures:after {
      background: url("../images/weather.png") no-repeat;
      content: "";
      display: block;
      height: 281px;
      position: absolute;
      right: 0;
      top: 0;
      width: 309px;
      z-index: 1; }
    .block--bean-wheater-endures h2 {
      font-size: 20px;
      padding-bottom: 30px;
      text-align: left;
      width: 70%; }
    .block--bean-wheater-endures .group-left {
      width: 44.91525%;
      float: left;
      margin-right: 1.69492%; }
    .block--bean-wheater-endures .group-right {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%;
      padding-top: 120px; }
    .block--bean-wheater-endures .field--name-field-block-body {
      text-align: left; } }

.block--views-what-we-do-block {
  background-image: url("../images/shattered.jpg");
  padding-bottom: 80px; }
  .block--views-what-we-do-block .block-inner-wrap {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .block--views-what-we-do-block .block-inner-wrap:after {
      content: " ";
      display: block;
      clear: both; }
  .block--views-what-we-do-block h2.block__title:before, .block--views-what-we-do-block h2.block__title:after {
    width: 35%; }
  .block--views-what-we-do-block .view {
    display: inline-block; }
    .block--views-what-we-do-block .view .views-row {
      margin-bottom: 20px; }

@media (min-width: 44em) {
  .block--views-what-we-do-block .view .views-row {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .block--views-what-we-do-block .view .views-row-even {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

.st-event-5 .group-content {
  background: url("../images/events-registrations/idrd.png") no-repeat;
  background-position: right 20px top 20px; }

.page-event.section-resultados .block--cool-tr-blocks-page-title {
  display: none; }

.entity-st-event.view-mode-full.sport-atletismo .group-header {
  background-image: url("../images/events-headers/header-athletics.jpg"); }

.entity-st-event.view-mode-full.sport-ciclismo-de-ruta .group-header {
  background-image: url("../images/events-headers/header-cycling.jpg"); }

.entity-st-event.view-mode-full.sport-mountain-bike .group-header {
  background-image: url("../images/events-headers/header-mtb.jpg"); }

.entity-st-event.view-mode-full.sport-down-hill .group-header {
  background-image: url("../images/events-headers/header-downhill.jpg"); }

.entity-st-event.view-mode-full.sport-cross-country .group-header {
  background-image: url("../images/events-headers/header-cross-country.jpg"); }

.entity-st-event.view-mode-full .group-header {
  background-image: url("../images/events-headers/header-athletics.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  box-sizing: border-box;
  color: white;
  height: 360px;
  padding-top: 40px;
  text-align: center; }
  .entity-st-event.view-mode-full .group-header h1 {
    color: white;
    font-size: 48px;
    line-height: 55px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6); }
  .entity-st-event.view-mode-full .group-header h1.name-length-long {
    font-size: 38px;
    margin-top: -20px; }
  .entity-st-event.view-mode-full .group-header .field--name-field-event-date {
    font-size: 30px;
    line-height: 32px;
    margin-top: 10px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6); }
  .entity-st-event.view-mode-full .group-header .event-category-selector {
    margin-top: 30px; }
    .entity-st-event.view-mode-full .group-header .event-category-selector select {
      width: 240px; }

.entity-st-event.view-mode-full .group-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0; }
  .entity-st-event.view-mode-full .group-content:after {
    content: " ";
    display: block;
    clear: both; }
  .entity-st-event.view-mode-full .group-content .tr-action-links {
    display: inline-block;
    float: left;
    margin-top: 20px;
    width: 100%; }

.entity-st-event.view-mode-full .event-results {
  text-transform: uppercase; }
  .entity-st-event.view-mode-full .event-results .quicktabs-tabs li {
    padding: 0; }
    .entity-st-event.view-mode-full .event-results .quicktabs-tabs li a {
      background-color: #b1b1b1;
      color: white;
      display: block;
      font-size: 24px;
      margin-bottom: 10px;
      padding: 20px 40px;
      text-align: center;
      text-decoration: none; }
    .entity-st-event.view-mode-full .event-results .quicktabs-tabs li.active a {
      background-color: #249fe2; }
  .entity-st-event.view-mode-full .event-results table {
    margin-bottom: 20px; }

@media (min-width: 44em) {
  .entity-st-event.view-mode-full .group-header {
    height: 425px;
    padding-top: 120px; }
    .entity-st-event.view-mode-full .group-header h1.name-length-long {
      font-size: 48px;
      margin-top: 0; }
    .entity-st-event.view-mode-full .group-header .event-category-selector {
      margin-top: 50px; }
  .entity-st-event.view-mode-full .event-results .quicktabs-tabs li {
    float: left;
    margin-right: 20px; }
    .entity-st-event.view-mode-full .event-results .quicktabs-tabs li a {
      margin-bottom: 20px; } }

.entity-st-event.view-mode-registration_box {
  background-color: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  text-align: left; }
  .entity-st-event.view-mode-registration_box .group-content {
    padding: 20px; }
  .entity-st-event.view-mode-registration_box .event-name {
    color: #249fe2; }
  .entity-st-event.view-mode-registration_box .event-sport {
    color: #249fe2;
    margin-bottom: 10px; }
  .entity-st-event.view-mode-registration_box .field--name-field-event-date {
    font-size: 18px; }
  .entity-st-event.view-mode-registration_box .register-button {
    background-color: #7c7e80;
    color: white;
    display: block;
    padding: 10px 0;
    text-align: center;
    width: 100%; }
    .entity-st-event.view-mode-registration_box .register-button:hover {
      background-color: #249fe2; }

.entity-st-event.view-mode-search_results {
  background-color: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  text-align: left; }
  .entity-st-event.view-mode-search_results .group-content {
    padding: 20px; }
  .entity-st-event.view-mode-search_results .event-name {
    color: #249fe2; }
  .entity-st-event.view-mode-search_results .event-sport {
    color: #249fe2;
    margin-bottom: 10px; }
  .entity-st-event.view-mode-search_results .field--name-field-event-date {
    font-size: 18px; }
  .entity-st-event.view-mode-search_results .view-button {
    background-color: #7c7e80;
    color: white;
    display: block;
    padding: 10px 0;
    text-align: center;
    width: 100%; }
    .entity-st-event.view-mode-search_results .view-button:hover {
      background-color: #249fe2; }

.entity-st-finish-result.view-mode-full.sport-atletismo .group-header {
  background-image: url("../images/events-headers/header-athletics.jpg"); }

.entity-st-finish-result.view-mode-full.sport-ciclismo-de-ruta .group-header {
  background-image: url("../images/events-headers/header-cycling.jpg"); }

.entity-st-finish-result.view-mode-full.sport-mountain-bike .group-header {
  background-image: url("../images/events-headers/header-mtb.jpg"); }

.entity-st-finish-result.view-mode-full.sport-down-hill .group-header {
  background-image: url("../images/events-headers/header-downhill.jpg"); }

.entity-st-finish-result.view-mode-full.sport-cross-country .group-header {
  background-image: url("../images/events-headers/header-cross-country.jpg"); }

.entity-st-finish-result.view-mode-full .group-header {
  background-image: url("../images/events-headers/header-athletics.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  box-sizing: border-box;
  color: white;
  height: 360px;
  padding: 40px 20px 20px 20px;
  text-align: center; }
  .entity-st-finish-result.view-mode-full .group-header h1 {
    color: white;
    font-size: 48px;
    line-height: 55px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6); }
  .entity-st-finish-result.view-mode-full .group-header h1.name-length-long {
    font-size: 38px;
    margin-top: -20px; }
  .entity-st-finish-result.view-mode-full .group-header .date {
    font-size: 20px;
    line-height: 32px;
    margin-top: 10px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6); }
  .entity-st-finish-result.view-mode-full .group-header .fr-contest {
    margin-top: 30px; }
    .entity-st-finish-result.view-mode-full .group-header .fr-contest span {
      background-color: rgba(0, 0, 0, 0.6);
      font-size: 20px;
      font-weight: bold;
      padding: 10px 20px; }

.entity-st-finish-result.view-mode-full .group-competitor {
  border-bottom: 1px solid #e4e4e4;
  margin: 20px 20px 0 20px;
  text-align: center; }
  .entity-st-finish-result.view-mode-full .group-competitor .fr-number {
    border-bottom: 1px solid #249fe2;
    color: #249fe2;
    display: inline-block;
    font-size: 50px;
    font-weight: bold;
    line-height: 56px;
    margin: 20px 0; }
  .entity-st-finish-result.view-mode-full .group-competitor .fr-rider-name {
    color: #4A4B4C;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px; }
  .entity-st-finish-result.view-mode-full .group-competitor .fr-category-name {
    color: #CCCCCC;
    font-size: 18px; }
  .entity-st-finish-result.view-mode-full .group-competitor .fr-certificate-button {
    margin: 20px 0; }

.entity-st-finish-result.view-mode-full .group-info {
  padding: 0 20px; }

.entity-st-finish-result.view-mode-full .fr-field-label {
  color: #CCCCCC;
  text-align: center;
  text-transform: uppercase; }

.entity-st-finish-result.view-mode-full .fr-field-content {
  color: #4A4B4C;
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 10px; }

.entity-st-finish-result.view-mode-full .fr-field-caption {
  color: #CCCCCC;
  font-size: 14px; }

.entity-st-finish-result.view-mode-full .fr-gun-time,
.entity-st-finish-result.view-mode-full .fr-chip-time {
  border-bottom: 1px solid #e4e4e4;
  padding: 30px 0;
  text-align: center; }
  .entity-st-finish-result.view-mode-full .fr-gun-time .fr-time,
  .entity-st-finish-result.view-mode-full .fr-chip-time .fr-time {
    color: #249fe2; }

.entity-st-finish-result.view-mode-full .fr-positions {
  border-bottom: 1px solid #e4e4e4;
  padding: 30px 0; }
  .entity-st-finish-result.view-mode-full .fr-positions .fr-positions-wrapper {
    display: inline-block;
    margin-top: 10px;
    width: 100%; }
  .entity-st-finish-result.view-mode-full .fr-positions .fr-category-position,
  .entity-st-finish-result.view-mode-full .fr-positions .fr-uncategorized-position {
    float: left;
    text-align: center;
    width: 50%; }
  .entity-st-finish-result.view-mode-full .fr-positions .fr-category-position .fr-position {
    color: #249fe2;
    font-size: 40px;
    font-weight: bold;
    line-height: 46px; }
  .entity-st-finish-result.view-mode-full .fr-positions .fr-uncategorized-position .fr-position {
    background-color: #249fe2;
    color: white;
    display: inline-block;
    font-size: 22px;
    margin: 4px 0;
    padding: 10px 20px; }

.entity-st-finish-result.view-mode-full .fr-speed {
  padding: 30px 0;
  text-align: center; }
  .entity-st-finish-result.view-mode-full .fr-speed .fr-field-content {
    color: #969899; }

.entity-st-finish-result.view-mode-full .field-group-div h2 {
  color: #4A4B4C;
  font-size: 40px;
  font-weight: bold;
  line-height: 46px;
  text-align: center; }

.entity-st-finish-result.view-mode-full .group-times {
  background-image: url("../images/bg-halftone.png");
  padding: 30px 20px 50px 20px; }
  .entity-st-finish-result.view-mode-full .group-times .fr-times-comparison {
    margin-top: 20px; }
  .entity-st-finish-result.view-mode-full .group-times .line-chart {
    display: block;
    height: 30px;
    float: left;
    position: relative;
    width: 30px; }
  .entity-st-finish-result.view-mode-full .group-times .line {
    border-right: 1px dashed #969899;
    height: 100%;
    left: 47%;
    position: absolute;
    width: 1px; }
  .entity-st-finish-result.view-mode-full .group-times .icon {
    color: #249fe2;
    font-size: 30px;
    position: absolute;
    width: 30px;
    z-index: 1; }
  .entity-st-finish-result.view-mode-full .group-times .icon:before {
    margin-left: 0; }
  .entity-st-finish-result.view-mode-full .group-times .first-icon {
    top: -15px; }
  .entity-st-finish-result.view-mode-full .group-times .last-icon {
    bottom: -15px;
    color: #7c0508; }
  .entity-st-finish-result.view-mode-full .group-times .competitor-icon {
    color: #ff9c00; }
  .entity-st-finish-result.view-mode-full .group-times .info-wrapper {
    margin-left: 40px;
    padding: 20px 0; }
  .entity-st-finish-result.view-mode-full .group-times .time-box {
    background-color: white;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.05);
    padding: 20px;
    position: relative; }
  .entity-st-finish-result.view-mode-full .group-times .time-icon {
    background-color: #249fe2;
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px; }
  .entity-st-finish-result.view-mode-full .group-times .time-label {
    color: #249fe2;
    font-size: 22px;
    line-height: 26px; }
  .entity-st-finish-result.view-mode-full .group-times .time-text {
    color: #969899;
    font-size: 18px;
    line-height: 22px; }
  .entity-st-finish-result.view-mode-full .group-times .time-diff {
    color: #969899;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    padding: 20px; }
    .entity-st-finish-result.view-mode-full .group-times .time-diff span {
      font-size: 16px;
      font-weight: 300;
      margin-left: 20px; }
  .entity-st-finish-result.view-mode-full .group-times .competitor-box .time-icon {
    background-color: #ff9c00; }
  .entity-st-finish-result.view-mode-full .group-times .last-box .time-icon {
    background-color: #7c0508; }

.entity-st-finish-result.view-mode-full .group-participation {
  padding: 30px 20px; }
  .entity-st-finish-result.view-mode-full .group-participation h2 {
    margin-bottom: 20px; }

.entity-st-finish-result.view-mode-full .fr-categories-chart {
  position: relative;
  height: 500px;
  width: 100%; }

.entity-st-finish-result.view-mode-full .fr-categories-chart.long-chart {
  height: 600px; }

@media (min-width: 44em) {
  .entity-st-finish-result.view-mode-full .group-header {
    height: 425px;
    padding-top: 120px; }
    .entity-st-finish-result.view-mode-full .group-header h1.name-length-long {
      font-size: 48px;
      margin-top: 0; }
  .entity-st-finish-result.view-mode-full .group-competitor {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0;
    position: relative; }
    .entity-st-finish-result.view-mode-full .group-competitor:after {
      content: " ";
      display: block;
      clear: both; }
    .entity-st-finish-result.view-mode-full .group-competitor .fr-number {
      border-bottom: none;
      border-right: 1px solid #249fe2;
      font-size: 60px;
      float: left;
      margin: 0;
      padding: 5px 20px; }
    .entity-st-finish-result.view-mode-full .group-competitor .fr-rider-name {
      float: left;
      margin-left: 20px;
      min-width: 75%;
      text-align: left; }
    .entity-st-finish-result.view-mode-full .group-competitor .fr-category-name {
      float: left;
      margin-left: 20px;
      min-width: 75%;
      text-align: left; }
    .entity-st-finish-result.view-mode-full .group-competitor .fr-certificate-button {
      position: absolute;
      right: 0;
      top: 0; }
  .entity-st-finish-result.view-mode-full .group-info {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0; }
    .entity-st-finish-result.view-mode-full .group-info:after {
      content: " ";
      display: block;
      clear: both; }
    .entity-st-finish-result.view-mode-full .group-info .fr-gun-time,
    .entity-st-finish-result.view-mode-full .group-info .fr-chip-time,
    .entity-st-finish-result.view-mode-full .group-info .fr-positions,
    .entity-st-finish-result.view-mode-full .group-info .fr-speed {
      border-bottom: none;
      float: left;
      padding: 15px 0;
      width: 24.57627%; }
    .entity-st-finish-result.view-mode-full .group-info .fr-positions {
      border-left: 1px solid #CCCCCC;
      border-right: 1px solid #CCCCCC;
      padding: 0; }
    .entity-st-finish-result.view-mode-full .group-info .fr-chip-time {
      border-left: 1px solid #CCCCCC; }
  .entity-st-finish-result.view-mode-full .group-times h2 {
    margin-bottom: 40px; }
  .entity-st-finish-result.view-mode-full .fr-times-comparison {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison:after {
      content: " ";
      display: block;
      clear: both; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .line-chart {
      float: none;
      margin: 20px 0;
      width: 100%; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .first-icon {
      top: 0;
      left: -15px; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .last-icon {
      bottom: 0;
      right: -15px; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .competitor-icon {
      background-color: white;
      border-radius: 50%;
      box-shadow: 2px 2px 7px rgba(255, 156, 0, 0.15);
      padding: 10px;
      top: -10px; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .line {
      border-right: none;
      border-top: 1px dashed #969899;
      height: 0;
      left: 0;
      top: 14px;
      width: 100%; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .info-wrapper {
      display: inline-block;
      margin-left: 0;
      width: 100%; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .time-box {
      box-sizing: border-box;
      float: left;
      width: 24%; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison .time-diff {
      box-sizing: border-box;
      float: left;
      text-align: center;
      width: 14%; }
      .entity-st-finish-result.view-mode-full .fr-times-comparison .time-diff span {
        display: inline-block;
        margin-left: 0; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison.first-or-last .info-wrapper {
      margin-left: 10%;
      width: 80%; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison.first-or-last .time-box {
      width: 40%; }
    .entity-st-finish-result.view-mode-full .fr-times-comparison.first-or-last .time-diff {
      width: 20%; }
  .entity-st-finish-result.view-mode-full .group-participation {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    .entity-st-finish-result.view-mode-full .group-participation:after {
      content: " ";
      display: block;
      clear: both; }
  .entity-st-finish-result.view-mode-full .fr-categories-chart {
    max-height: 400px;
    max-width: 600px;
    margin: 0 auto; }
  .entity-st-finish-result.view-mode-full .fr-categories-chart.long-chart {
    max-width: 800px; } }

.l-footer {
  background-color: #333333;
  color: white;
  text-align: center; }

.l-header {
  border-bottom: thin solid transparent;
  left: 0;
  position: fixed;
  transition: all 0.5s ease-out;
  top: 0;
  width: 100%;
  z-index: 2; }
  .l-header .logo {
    background: url("../images/logo.png") no-repeat 0 -40px;
    display: block;
    float: left;
    height: 40px;
    width: 40px; }
  .l-header .site-logo {
    padding: 0;
    display: inline-block; }
    .l-header .site-logo .site-name {
      border-left: thin solid #333333;
      color: #249fe2;
      display: none;
      font-family: 'Carter One', cursive;
      font-size: 30px;
      float: left;
      margin-left: 4px;
      margin-top: 4px;
      padding: 5px 8px; }
      .l-header .site-logo .site-name span {
        color: #333333;
        margin-left: -5px; }

.l-header.header-fixed {
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  padding: 10px 10px 8px 10px; }
  .l-header.header-fixed .site-logo .site-name {
    display: block; }

.node-type-service .breadcrumb {
  display: none; }

.node-type-service .block--cool-tr-blocks-page-title {
  padding: 0;
  position: absolute;
  text-align: center;
  margin-top: 120px;
  width: 100%;
  z-index: 1; }
  .node-type-service .block--cool-tr-blocks-page-title h1 {
    color: #CCCCCC;
    font-size: 36px;
    line-height: 40px;
    padding: 0 20px; }
  .node-type-service .block--cool-tr-blocks-page-title:before {
    border-top: thin dashed #CCCCCC;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    margin-left: -50px;
    position: absolute;
    top: -50px;
    width: 100px; }

.node-type-service .group-header {
  background: url("../images/triangles.png");
  height: 300px;
  position: relative; }
  .node-type-service .group-header .field--name-field-image {
    bottom: -48px;
    left: 50%;
    margin-left: -48px;
    position: absolute; }
    .node-type-service .group-header .field--name-field-image .field__item {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 48px;
      display: block;
      height: 78px;
      padding: 9px;
      width: 78px; }

.node-type-service .node--service {
  text-align: center; }
  .node-type-service .node--service .field--name-body {
    padding: 70px 20px 40px 20px; }

@media (min-width: 44em) {
  .node-type-service .block--cool-tr-blocks-page-title {
    margin-top: 140px; }
    .node-type-service .block--cool-tr-blocks-page-title h1 {
      font-size: 48px;
      line-height: 50px; }
    .node-type-service .block--cool-tr-blocks-page-title:before {
      margin-left: -108px;
      width: 216px; }
  .node-type-service .node--service .field--name-body {
    font-size: 20px;
    line-height: 24px;
    max-width: 600px;
    margin: 0 auto;
    padding: 120px 20px 100px 20px; }
    .node-type-service .node--service .field--name-body p {
      margin-bottom: 20px; } }

.node--service--teaser {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 30px;
  text-align: center; }
  .node--service--teaser .field--name-title {
    font-size: 18px;
    padding-top: 5px; }
  .node--service--teaser .field--name-field-image {
    display: inline-block;
    margin-bottom: 20px; }
    .node--service--teaser .field--name-field-image img {
      display: block; }
  .node--service--teaser .field--name-node-link {
    border-top: thin solid #CCCCCC;
    margin-top: 10px; }
    .node--service--teaser .field--name-node-link a {
      color: #333333;
      display: block;
      font-family: 'Lato', sans-serif;
      padding-top: 20px;
      text-decoration: none;
      width: 100%; }

@media (min-width: 44em) {
  .node--service--teaser {
    position: relative;
    text-align: left; }
    .node--service--teaser:hover .field--name-node-link a {
      width: 140px; }
    .node--service--teaser .field--name-title {
      font-size: 20px; }
    .node--service--teaser .field--name-field-image {
      margin-bottom: 0; }
    .node--service--teaser .group-left {
      float: left;
      height: 79px; }
    .node--service--teaser .group-right {
      display: table-cell;
      height: 79px;
      padding-left: 20px;
      vertical-align: middle; }
    .node--service--teaser .field--name-node-link {
      border: none;
      margin: 0;
      position: static; }
      .node--service--teaser .field--name-node-link a {
        background-color: #333333;
        box-sizing: border-box;
        color: white;
        height: 100%;
        font-weight: 300;
        overflow: hidden;
        position: absolute;
        right: 0;
        transition: width 0.5s;
        top: 0;
        text-align: center;
        width: 0;
        z-index: 1; }
      .node--service--teaser .field--name-node-link a:before {
        display: block;
        font-size: 36px;
        margin: 15px auto; }
      .node--service--teaser .field--name-node-link a:hover {
        background-color: #404040; } }

.page-checkout .commerce-checkout-form-checkout,
.page-checkout .commerce-checkout-form-review,
.page-checkout .commerce-payulatam-redirect-form {
  padding: 20px; }

.page-checkout table th {
  padding: 0 10px; }

.page-checkout fieldset {
  margin-bottom: 20px; }
  .page-checkout fieldset legend {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px; }

.page-checkout select {
  width: 100%; }

.page-checkout div.addressfield-container-inline > div.form-item {
  float: none;
  margin-right: 0; }

.page-checkout .button-operator {
  display: none; }

.page-checkout .checkout-continue {
  margin-bottom: 20px; }

.page-checkout .checkout-cancel {
  background-color: #d94f13;
  color: white;
  padding: 10px 20px; }
  .page-checkout .checkout-cancel:hover {
    background-color: #7b2d0b;
    color: white;
    text-decoration: none; }

.page-checkout .checkout-buttons {
  border-top: thin solid #e4e4e4;
  padding-top: 20px; }

.page-checkout table.checkout-review .pane-data-full {
  padding-left: 0;
  padding-right: 0; }

.page-checkout .checkout-help {
  margin: 20px 0;
  text-align: center; }

.page-checkout .commerce_payment {
  display: none; }

.page-checkout div.checkout_review {
  margin-bottom: 20px; }

.page-checkout .commerce-payulatam-redirect-form {
  text-align: center; }

@media (min-width: 44em) {
  .page-checkout select {
    width: 99%; }
  .page-checkout input.form-text {
    width: 99%; }
  .page-checkout .commerce-checkout-form-checkout,
  .page-checkout .commerce-checkout-form-review,
  .page-checkout .commerce-payulatam-redirect-form {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .page-checkout .commerce-checkout-form-checkout:after,
    .page-checkout .commerce-checkout-form-review:after,
    .page-checkout .commerce-payulatam-redirect-form:after {
      content: " ";
      display: block;
      clear: both; }
  .page-checkout .customer_profile_billing,
  .page-checkout .account {
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0m; }
  .page-checkout .customer_profile_billing {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .page-checkout .account {
    width: 49.15254%;
    float: right;
    margin-right: 0; }
  .page-checkout #edit-account-login-mail {
    height: 48px; }
  .page-checkout .checkout-buttons {
    clear: both;
    padding-top: 20px;
    text-align: center;
    width: 100%; }
    .page-checkout .checkout-buttons .fieldset-wrapper {
      display: inline-block; }
  .page-checkout .checkout-cancel {
    padding: 20px 40px;
    margin-left: 20px; } }

.page-confirm-contact .breadcrumb {
  display: none; }

.page-confirm-contact .confirm-page-content {
  font-size: 24px;
  line-height: 30px;
  padding: 50px 0;
  text-align: center; }

@media (min-width: 44em) {
  .page-confirm-contact .page-title {
    font-size: 40px;
    margin-top: 100px; }
  .page-confirm-contact .confirm-page-content {
    padding: 50px 30%; } }

.page-event-register-duplicated,
.page-event-register-limit,
.page-event-register-success {
  background-color: #f8f8f8; }
  .page-event-register-duplicated .page-title,
  .page-event-register-limit .page-title,
  .page-event-register-success .page-title {
    color: #249fe2;
    margin-top: 30px; }
  .page-event-register-duplicated .breadcrumb,
  .page-event-register-limit .breadcrumb,
  .page-event-register-success .breadcrumb {
    display: none; }
  .page-event-register-duplicated .registration-confirmation-message,
  .page-event-register-limit .registration-confirmation-message,
  .page-event-register-success .registration-confirmation-message {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    max-width: 600px;
    margin: 20px;
    padding: 20px;
    text-align: center; }
  .page-event-register-duplicated .general-confirmation-message,
  .page-event-register-limit .general-confirmation-message,
  .page-event-register-success .general-confirmation-message {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px; }
  .page-event-register-duplicated .event-confirmation-message,
  .page-event-register-limit .event-confirmation-message,
  .page-event-register-success .event-confirmation-message {
    border-top: thin solid #f8f8f8;
    margin-top: 1em;
    padding-top: 1em; }
  .page-event-register-duplicated .registration-thanks,
  .page-event-register-limit .registration-thanks,
  .page-event-register-success .registration-thanks {
    color: #CCCCCC;
    font-size: 40px;
    line-height: 40px;
    margin: 30px 0;
    text-align: center; }

@media (min-width: 44em) {
  .page-event-register-duplicated .page-title,
  .page-event-register-limit .page-title,
  .page-event-register-success .page-title {
    font-size: 40px;
    margin: 50px 0; }
  .page-event-register-duplicated .registration-confirmation-message,
  .page-event-register-limit .registration-confirmation-message,
  .page-event-register-success .registration-confirmation-message {
    margin: 0 auto; } }

.page-event-register,
.page-event-all-register {
  background-color: #f8f8f8; }
  .page-event-register #edit-actions-prev,
  .page-event-register *[id^='edit-paging-header'],
  .page-event-all-register #edit-actions-prev,
  .page-event-all-register *[id^='edit-paging-header'] {
    display: none; }
  .page-event-register .page-title,
  .page-event-all-register .page-title {
    color: #249fe2;
    margin-top: 50px; }
  .page-event-register .breadcrumb,
  .page-event-all-register .breadcrumb {
    display: none; }
  .page-event-register .event-registration-form,
  .page-event-all-register .event-registration-form {
    background-color: white;
    max-width: 600px;
    padding: 20px; }
    .page-event-register .event-registration-form .registration-notes,
    .page-event-all-register .event-registration-form .registration-notes {
      border: thin solid #d94f13;
      margin-bottom: 20px;
      padding: 20px; }
      .page-event-register .event-registration-form .registration-notes .fieldset-legend,
      .page-event-all-register .event-registration-form .registration-notes .fieldset-legend {
        color: #d94f13;
        font-size: 20px;
        font-weight: bold;
        padding: 10px; }
    .page-event-register .event-registration-form .event-registrations-image,
    .page-event-all-register .event-registration-form .event-registrations-image {
      margin-bottom: 20px; }
    .page-event-register .event-registration-form .form-type-select,
    .page-event-all-register .event-registration-form .form-type-select {
      position: relative;
      width: 100%; }
    .page-event-register .event-registration-form select,
    .page-event-all-register .event-registration-form select {
      width: 100%; }
  .page-event-register #edit-category-finder,
  .page-event-all-register #edit-category-finder {
    border-bottom: thin solid #e4e4e4;
    border-top: thin solid #e4e4e4;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px 0 0 0; }
    .page-event-register #edit-category-finder legend,
    .page-event-all-register #edit-category-finder legend {
      text-align: center; }
      .page-event-register #edit-category-finder legend span,
      .page-event-all-register #edit-category-finder legend span {
        background-color: white;
        font-weight: bold;
        margin-top: -30px;
        padding: 10px 20px;
        position: relative; }
    .page-event-register #edit-category-finder #tr-registration-found-category,
    .page-event-all-register #edit-category-finder #tr-registration-found-category {
      font-size: 22px;
      font-weight: bold;
      float: left;
      margin-bottom: 20px;
      text-align: center;
      width: 100%; }
  .page-event-register .group-tshirt,
  .page-event-all-register .group-tshirt {
    border-bottom: thin solid #e4e4e4;
    margin-bottom: 20px; }
    .page-event-register .group-tshirt legend,
    .page-event-all-register .group-tshirt legend {
      margin-bottom: 20px;
      text-align: center;
      font-weight: bold; }
  .page-event-register #block-bean-event-registration-notes,
  .page-event-all-register #block-bean-event-registration-notes {
    margin: 0 auto;
    max-width: 600px;
    text-align: center; }
    .page-event-register #block-bean-event-registration-notes span,
    .page-event-all-register #block-bean-event-registration-notes span {
      color: #d94f13; }

@media (min-width: 44em) {
  .page-event-register .page-title,
  .page-event-all-register .page-title {
    font-size: 40px;
    margin: 50px 0; }
  .page-event-register .event-registration-form,
  .page-event-all-register .event-registration-form {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    padding: 40px; }
    .page-event-register .event-registration-form input.form-text,
    .page-event-all-register .event-registration-form input.form-text {
      width: 100%; }
    .page-event-register .event-registration-form .form-item,
    .page-event-all-register .event-registration-form .form-item {
      margin-bottom: 2em; }
    .page-event-register .event-registration-form .form-actions,
    .page-event-all-register .event-registration-form .form-actions {
      border-top: thin solid #e4e4e4;
      height: 30px;
      margin-top: 70px;
      margin-bottom: 0;
      padding-top: 0;
      text-align: center; }
    .page-event-register .event-registration-form .form-actions input,
    .page-event-all-register .event-registration-form .form-actions input {
      border-left: 20px solid white;
      border-right: 20px solid white;
      position: relative;
      top: -30px; }
  .page-event-register #edit-category-finder .form-item-category-finder-year-of-birth input,
  .page-event-all-register #edit-category-finder .form-item-category-finder-year-of-birth input {
    height: 48px; }
  .page-event-register #edit-category-finder .fieldset-wrapper > .form-item,
  .page-event-all-register #edit-category-finder .fieldset-wrapper > .form-item {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .page-event-register #edit-category-finder #tr-registration-category-select,
  .page-event-all-register #edit-category-finder #tr-registration-category-select {
    width: 32.20339%;
    float: right;
    margin-right: 0; }
  .page-event-register .group-tshirt .field-name-field-add-tshirt,
  .page-event-all-register .group-tshirt .field-name-field-add-tshirt {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%;
    text-align: center; }
    .page-event-register .group-tshirt .field-name-field-add-tshirt .form-item,
    .page-event-all-register .group-tshirt .field-name-field-add-tshirt .form-item {
      display: inline-block;
      padding-top: 30px; }
  .page-event-register .group-tshirt .field-name-field-size,
  .page-event-all-register .group-tshirt .field-name-field-size {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }

.page-payulatam-response .l-content {
  padding: 20px;
  margin-top: 50px; }

@media (min-width: 44em) {
  .page-payulatam-response .l-content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .page-payulatam-response .l-content:after {
      content: " ";
      display: block;
      clear: both; } }

.page-registrations-to-events .block--cool-tr-blocks-page-title {
  margin-top: 40px;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2; }
  .page-registrations-to-events .block--cool-tr-blocks-page-title h1 {
    color: white;
    font-size: 48px;
    line-height: 55px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6); }

.register-to-events-header {
  background: url("../images/bg-registrations.jpg") no-repeat top center;
  box-sizing: border-box;
  color: white;
  height: 360px;
  padding: 90px 0 0 0;
  text-align: center; }
  .register-to-events-header .page-description {
    font-size: 20px;
    line-height: 22px;
    padding: 20px; }
  .register-to-events-header .views-exposed-widget {
    float: none;
    padding: 0; }
  .register-to-events-header .form-item-sport select {
    width: 200px; }
  .register-to-events-header .views-widget-filter-name {
    margin-top: 20px;
    padding: 0 16px; }

.view-registrations-to-events.view-display-id-page {
  background-image: url("../images/shattered.jpg");
  text-align: center; }
  .view-registrations-to-events.view-display-id-page .view-empty {
    font-size: 20px;
    padding: 20px; }
  .view-registrations-to-events.view-display-id-page .views-row-last {
    margin-bottom: 0; }

@media (min-width: 44em) {
  .page-registrations-to-events .block--cool-tr-blocks-page-title {
    margin-top: 90px; }
  .register-to-events-header {
    padding: 140px 0 0 0; }
    .register-to-events-header .views-exposed-widgets {
      display: inline-block; }
    .register-to-events-header .views-exposed-widget {
      float: left; }
    .register-to-events-header .views-widget-filter-name {
      padding: 0;
      margin: 0 0 0 20px; }
  .view-registrations-to-events.view-display-id-page .view-content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .view-registrations-to-events.view-display-id-page .view-content:after {
      content: " ";
      display: block;
      clear: both; } }

.page-results .block--cool-tr-blocks-page-title {
  margin-top: 40px;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2; }
  .page-results .block--cool-tr-blocks-page-title h1 {
    color: white;
    font-size: 48px;
    line-height: 55px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6); }

.block--views-exp-events-page {
  background: url("../images/bg-results-search.jpg") no-repeat top center;
  box-sizing: border-box;
  color: white;
  height: 360px;
  padding: 90px 0 0 0;
  text-align: center; }
  .block--views-exp-events-page .page-description {
    font-size: 20px;
    line-height: 22px;
    padding: 20px; }
  .block--views-exp-events-page .views-exposed-widget {
    float: none;
    padding: 0; }
  .block--views-exp-events-page .form-item-sport select {
    width: 200px; }
  .block--views-exp-events-page .views-widget-filter-name {
    margin-top: 20px;
    padding: 0 16px; }

.view-events.view-display-id-page {
  background-image: url("../images/shattered.jpg");
  text-align: center; }
  .view-events.view-display-id-page .view-empty {
    font-size: 20px;
    padding: 20px; }
  .view-events.view-display-id-page .views-row-last {
    margin-bottom: 0; }

@media (min-width: 44em) {
  .page-results .block--cool-tr-blocks-page-title {
    margin-top: 90px; }
  .block--views-exp-events-page {
    padding: 140px 0 0 0; }
    .block--views-exp-events-page .views-exposed-widgets {
      display: inline-block; }
    .block--views-exp-events-page .views-exposed-widget {
      float: left; }
    .block--views-exp-events-page .views-widget-filter-name {
      padding: 0;
      margin: 0 0 0 20px; }
  .view-events.view-display-id-page .view-content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .view-events.view-display-id-page .view-content:after {
      content: " ";
      display: block;
      clear: both; } }
