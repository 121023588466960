.entity-st-finish-result.view-mode-full {
  &.sport-atletismo .group-header {
    background-image: url('../images/events-headers/header-athletics.jpg');
  }
  &.sport-ciclismo-de-ruta .group-header {
    background-image: url('../images/events-headers/header-cycling.jpg');
  }
  &.sport-mountain-bike .group-header {
    background-image: url('../images/events-headers/header-mtb.jpg');
  }
  &.sport-down-hill .group-header {
    background-image: url('../images/events-headers/header-downhill.jpg');
  }
  &.sport-cross-country .group-header {
    background-image: url('../images/events-headers/header-cross-country.jpg');
  }
  // Header.
  .group-header {
    background-image: url('../images/events-headers/header-athletics.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    box-sizing: border-box;
    color: white;
    height: 360px;
    padding: 40px 20px 20px 20px;
    text-align: center;

    h1 {
      color: white;
      font-size: 48px;
      line-height: 55px;
      text-shadow: 2px 2px 5px rgba(black, 0.60);
    }
    h1.name-length-long {
      font-size: 38px;
      margin-top: -20px;
    }
    .date {
      font-size: 20px;
      line-height: 32px;
      margin-top: 10px;
      text-shadow: 2px 2px 5px rgba(black, 0.60)
    }
    .fr-contest {
      margin-top: 30px;
      span {
        background-color: rgba(black, 0.60);
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
      }
    }
  }
  // Number and Name.
  .group-competitor {
    border-bottom: 1px solid $mercury;
    margin: 20px 20px 0 20px;
    text-align: center;
    .fr-number {
      border-bottom: 1px solid $blue;
      color: $blue;
      display: inline-block;
      font-size: 50px;
      font-weight: bold;
      line-height: 56px;
      margin: 20px 0;
    }
    .fr-rider-name {
      color: $certificate-gray;
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
    }
    .fr-category-name {
      color: $light-gray;
      font-size: 18px;
    }
    .fr-certificate-button {
      margin: 20px 0;
    }
  }
  // Useful info.
  .group-info {
    padding: 0 20px;
  }
  .fr-field-label {
    color: $light-gray;
    text-align: center;
    text-transform: uppercase;
  }
  .fr-field-content {
    color: $certificate-gray;
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 10px;
  }
  .fr-field-caption {
    color: $light-gray;
    font-size: 14px;
  }
  .fr-gun-time,
  .fr-chip-time {
    border-bottom: 1px solid $mercury;
    padding: 30px 0;
    text-align: center;
    .fr-time {
      color: $blue;
    }
  }
  .fr-positions {
    border-bottom: 1px solid $mercury;
    padding: 30px 0;
    .fr-positions-wrapper {
      display: inline-block;
      margin-top: 10px;
      width: 100%;
    }
    .fr-category-position,
    .fr-uncategorized-position {
      float: left;
      text-align: center;
      width: 50%;
    }
    .fr-category-position {
      .fr-position {
        color: $blue;
        font-size: 40px;
        font-weight: bold;
        line-height: 46px;
      }
    }
    .fr-uncategorized-position {
      .fr-position {
        background-color: $blue;
        color: white;
        display: inline-block;
        font-size: 22px;
        margin: 4px 0;
        padding: 10px 20px;
      }
    }
  }
  .fr-speed {
    padding: 30px 0;
    text-align: center;
    .fr-field-content {
      color: $gray;
    }
  }
  // Times Comparison.
  .field-group-div h2 {
    color: $certificate-gray;
    font-size: 40px;
    font-weight: bold;
    line-height: 46px;
    text-align: center;
  }
  .group-times {
    background-image: url("../images/bg-halftone.png");
    padding: 30px 20px 50px 20px;
    .fr-times-comparison {
      margin-top: 20px;
    }
    // Line.
    .line-chart {
      display: block;
      height: 30px;
      float: left;
      position: relative;
      width: 30px;
    }
    .line {
      border-right: 1px dashed $gray;
      height: 100%;
      left: 47%;
      position: absolute;
      width: 1px;
    }
    .icon {
      color: $blue;
      font-size: 30px;
      position: absolute;
      width: 30px;
      z-index: 1;
    }
    .icon:before {
      margin-left: 0;
    }
    .first-icon {
      top: -15px;
    }
    .last-icon {
      bottom: -15px;
      color: $dark-burgundy;
    }
    .competitor-icon {
      color: $orange-peel;
    }
    // Boxes.
    .info-wrapper {
      margin-left: 40px;
      padding: 20px 0;
    }
    .time-box {
      background-color: white;
      box-shadow: 2px 2px 7px rgba(black, 0.05);
      padding: 20px;
      position: relative;
    }
    .time-icon {
      background-color: $blue;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 30px;
    }
    .time-label {
      color: $blue;
      font-size: 22px;
      line-height: 26px;
    }
    .time-text {
      color: $gray;
      font-size: 18px;
      line-height: 22px;
    }
    .time-diff {
      color: $gray;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      padding: 20px;
      span {
        font-size: 16px;
        font-weight: 300;
        margin-left: 20px;
      }
    }
    .competitor-box .time-icon {
      background-color: $orange-peel;
    }
    .last-box .time-icon {
      background-color: $dark-burgundy;
    }
  }
  // Participation.
  .group-participation {
    padding: 30px 20px;
    h2 {
      margin-bottom: 20px;
    }
  }
  .fr-categories-chart {
    position: relative;
    height: 500px;
    width: 100%;
  }
  .fr-categories-chart.long-chart {
    height: 600px;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .entity-st-finish-result.view-mode-full {
    .group-header {
      height: 425px;
      padding-top: 120px;

      h1.name-length-long {
        font-size: 48px;
        margin-top: 0;
      }
    }
    // Number and Name.
    .group-competitor {
      @include container(1000px);
      padding: 30px 0;
      position: relative;
      .fr-number {
        border-bottom: none;
        border-right: 1px solid $blue;
        font-size: 60px;
        float: left;
        margin: 0;
        padding: 5px 20px;
      }
      .fr-rider-name {
        float: left;
        margin-left: 20px;
        min-width: 75%;
        text-align: left;
      }
      .fr-category-name {
        float: left;
        margin-left: 20px;
        min-width: 75%;
        text-align: left;
      }
      .fr-certificate-button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .group-info {
      @include container(1000px);
      padding: 30px 0;
      .fr-gun-time,
      .fr-chip-time,
      .fr-positions,
      .fr-speed {
        border-bottom: none;
        float: left;
        padding: 15px 0;
        width: span(3 of $tab-columns) + (gutter($tab-columns) / 2);
      }
      .fr-positions {
        border-left: 1px solid $light-gray;
        border-right: 1px solid $light-gray;
        padding: 0;
      }
      .fr-chip-time {
        border-left: 1px solid $light-gray;
      }
    }
    // Times comparison.
    .group-times {
      h2 {
        margin-bottom: 40px;
      }
    }
    .fr-times-comparison {
      @include container(1000px);
      
      .line-chart {
        float: none;
        margin: 20px 0;
        width: 100%;
      }
      .first-icon {
        top: 0;
        left: -15px;
      }
      .last-icon {
        bottom: 0;
        right: -15px;
      }
      .competitor-icon {
        background-color: white;
        border-radius: 50%;
        box-shadow: 2px 2px 7px rgba($orange-peel, 0.15);
        padding: 10px;
        top: -10px;
      }
      .line {
        border-right: none;
        border-top: 1px dashed $gray;
        height: 0;
        left: 0;
        top: 14px;
        width: 100%;
      }
      .info-wrapper {
        display: inline-block;
        margin-left: 0;
        width: 100%;
      }
      .time-box {
        box-sizing: border-box;
        float: left;
        width: 24%;
      }
      .time-diff {
        box-sizing: border-box;
        float: left;
        text-align: center;
        width: 14%;
        span {
          display: inline-block;
          margin-left: 0;
        }
      }
      &.first-or-last {
        .info-wrapper {
          margin-left: 10%;
          width: 80%;
        }
        .time-box {
          width: 40%;
        }
        .time-diff {
          width: 20%;
        }
      }
    }
    // Participation.
    .group-participation {
      @include container(1000px);
      text-align: center;
    }
    .fr-categories-chart {
      max-height: 400px;
      max-width: 600px;
      margin: 0 auto;
    }
    .fr-categories-chart.long-chart {
      max-width: 800px;
    }
  }
}
