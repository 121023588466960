.page-event.section-resultados {
  .block--cool-tr-blocks-page-title {
    display: none;
  }
}

.entity-st-event.view-mode-full {
  &.sport-atletismo .group-header {
    background-image: url('../images/events-headers/header-athletics.jpg');
  }
  &.sport-ciclismo-de-ruta .group-header {
    background-image: url('../images/events-headers/header-cycling.jpg');
  }
  &.sport-mountain-bike .group-header {
    background-image: url('../images/events-headers/header-mtb.jpg');
  }
  &.sport-down-hill .group-header {
    background-image: url('../images/events-headers/header-downhill.jpg');
  }
  &.sport-cross-country .group-header {
    background-image: url('../images/events-headers/header-cross-country.jpg');
  }
  .group-header {
    background-image: url('../images/events-headers/header-athletics.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    box-sizing: border-box;
    color: white;
    height: 360px;
    padding-top: 40px;
    text-align: center;

    h1 {
      color: white;
      font-size: 48px;
      line-height: 55px;
      text-shadow: 2px 2px 5px rgba(black, 0.60)
    }
    h1.name-length-long {
      font-size: 38px;
      margin-top: -20px;
    }
    .field--name-field-event-date {
      font-size: 30px;
      line-height: 32px;
      margin-top: 10px;
      text-shadow: 2px 2px 5px rgba(black, 0.60)
    }
    .event-category-selector {
      margin-top: 30px;
      select {
        width: 240px;
      }
    }
  }
  .group-content {
    @include container;
    padding: 40px 0;

    .tr-action-links {
      display: inline-block;
      float: left;
      margin-top: 20px;
      width: 100%;
    }
  }
  .event-results {
    text-transform: uppercase;
    .quicktabs-tabs li {
      padding: 0;
      a {
        background-color: darken($mercury, 20%);
        color: white;
        display: block;
        font-size: 24px;
        margin-bottom: 10px;
        padding: 20px 40px;
        text-align: center;
        text-decoration: none;
      }
      &.active a {
        background-color: $blue;
      }
    }
    table {
      margin-bottom: 20px;
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .entity-st-event.view-mode-full {
    .group-header {
      height: 425px;
      padding-top: 120px;

      h1.name-length-long {
        font-size: 48px;
        margin-top: 0;
      }
      .event-category-selector {
        margin-top: 50px;
      }
    }
    .event-results {
      .quicktabs-tabs li {
        float: left;
        margin-right: 20px;
        a {
          margin-bottom: 20px;
        }
      }
    }
  }
}
