[class^="icon-"], [class*=" icon-"] {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;

  /* fix buttons height */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
}

.icon-plus { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#x2b;&nbsp;'); }
.icon-award { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe801;&nbsp;'); }
.icon-user { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe807;&nbsp;'); }