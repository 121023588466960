.page-payulatam-response {
  .l-content {
    padding: 20px;
    margin-top: 50px;
  }
}
// Tablet.
@include breakpoint($tablet) {
  .page-payulatam-response {
    .l-content {
      @include container;
    }
  }
}