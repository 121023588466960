.block--tr-blocks-search-by-number {
  h2 {
    padding: 20px 0;
  }
  .block-inner-wrap {
    @include container(800px);
  }
}
// Tablet.
@include breakpoint($tablet) {
  .block--tr-blocks-search-by-number {
    .form-item-event {
      @include span(8 of $tab-columns);
      input {
        width: 100%;
      }
    }
    .form-item-number {
      @include span(4 of $tab-columns last);
    }
    .form-actions {
      border-top: thin solid $mercury;
      float: left;
      height: 40px;
      margin-top: 40px;
      padding-top: 0;
      text-align: center;
      width: 100%;

      input {
        border-left: 20px solid white;
        border-right: 20px solid white;
        position: relative;
        top: -30px;
      }
    }
  }
}
