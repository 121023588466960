.node--service--teaser {
  background-color: rgba(white, 0.5);
  padding: 30px;
  text-align: center;

  .field--name-title {
    font-size: 18px;
    padding-top: 5px;
  }
  .field--name-field-image {
    display: inline-block;
    margin-bottom: 20px;

    img {
      display: block;
    }
  }
  .field--name-node-link {
    border-top: thin solid $light-gray;
    margin-top: 10px;
    a {
      color: $mine-shaft;
      display: block;
      font-family: 'Lato', sans-serif;
      padding-top: 20px;
      text-decoration: none;
      width: 100%;
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .node--service--teaser {
    position: relative;
    text-align: left;

    &:hover {
      .field--name-node-link a {
        width: 140px;
      }
    }
    .field--name-title {
      font-size: 20px;
    }
    .field--name-field-image {
      margin-bottom: 0;
    }
    .group-left {
      float: left;
      height: 79px;
    }
    .group-right {
      display: table-cell;
      height: 79px;
      padding-left: 20px;
      vertical-align: middle;
    }
    .field--name-node-link {
      border: none;
      margin: 0;
      position: static;

      a {
        background-color: $mine-shaft;
        box-sizing: border-box;
        color: white;
        height: 100%;
        font-weight: 300;
        overflow: hidden;
        position: absolute;
        right: 0;
        transition: width 0.5s;
        top: 0;
        text-align: center;
        width: 0;
        z-index: 1;
      }
      a:before {
        display: block;
        font-size: 36px;
        margin: 15px auto;
      }
      a:hover {
        background-color: lighten($mine-shaft, 5%);
      }
    }
  }
}
