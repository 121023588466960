.block-one-team {
  background-color: $blue;
  color: white;
  .block__content {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block-one-team {
    background-image: url("../images/bg-one-team.jpg");
    background-position: center center;

    .block__content {
      background-color: rgba($blue, 0.8);
      font-size: 24px;
      line-height: 30px;
      max-width: 700px;
      margin: 200px auto;
      padding: 40px;
    }
  }
}
