.page-checkout {
  .commerce-checkout-form-checkout,
  .commerce-checkout-form-review,
  .commerce-payulatam-redirect-form {
    padding: 20px;
  }
  table {
    th {
      padding: 0 10px;
    }
  }
  fieldset {
    margin-bottom: 20px;
    legend {
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  select {
    width: 100%;
  }
  div.addressfield-container-inline > div.form-item {
    float: none;
    margin-right: 0;
  }
  .button-operator {
    display: none;
  }
  .checkout-continue {
    margin-bottom: 20px;
  }
  .checkout-cancel {
    background-color: $red;
    color: white;
    padding: 10px 20px;
    &:hover {
      background-color: darken($red, 20%);
      color: white;
      text-decoration: none;
    }
  }
  .checkout-buttons {
    border-top: thin solid $mercury;
    padding-top: 20px;
  }

  // Review.
  table.checkout-review .pane-data-full {
    padding-left: 0;
    padding-right: 0;
  }
  .checkout-help {
    margin: 20px 0;
    text-align: center;
  }
  .commerce_payment {
    display: none;
  }
  div.checkout_review {
    margin-bottom: 20px;
  }

  // Redirect.
  .commerce-payulatam-redirect-form {
    text-align: center;
  }
}
// Tablet.
@include breakpoint($tablet) {
  .page-checkout {
    select {
      width: 99%;
    }
    input.form-text {
      width: 99%;
    }
    .commerce-checkout-form-checkout,
    .commerce-checkout-form-review,
    .commerce-payulatam-redirect-form {
      @include container;
    }
    .customer_profile_billing,
    .account {
      padding-block-start: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-block-end: 0m;
    }
    .customer_profile_billing {
      @include span(6 of $tab-columns);
    }
    .account {
      @include span(6 of $tab-columns last);
    }
    #edit-account-login-mail {
      height: 48px;
    }
    .checkout-buttons {
      clear: both;
      padding-top: 20px;
      text-align: center;
      width: 100%;
      .fieldset-wrapper {
        display: inline-block;
      }
    }
    .checkout-cancel {
      padding: 20px 40px;
      margin-left: 20px;
    }
  }
}