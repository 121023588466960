.block--bean-contact-phone {
  background-color: $blue;
  text-align: center;

  .field--name-field-block-body {
    span {
      display: block;
      font-size: 24px;
      width: 100%;
    }
    a {
      background-color: lighten($blue, 20%);
      border-radius: 20px;
      color: darken($blue, 15%);
      display: inline-block;
      margin-top: 20px;
      padding: 10px 20px;
      text-decoration: none;
    }
    a:hover {
      background-color: lighten($blue, 30%);
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block--bean-contact-phone {
    .field--name-field-block-body {
      display: inline-block;
      font-size: 22px;

      .left {
        border-right: thin dashed white;
        float: left;
        height: 21px;
        margin-top: 12px;
        padding-right: 12px;
      }
      span {
        display: inline;
        font-size: 34px;
        width: auto;
      }
      a {
        font-size: 18px;
        margin-top: 0;
        margin-left: 12px;
      }
    }
  }
  .front {
    .block--bean-contact-phone .field--name-field-block-body .left {
      border-right: none;
      padding-right: 0;
    }
    .block--bean-contact-phone .field--name-field-block-body a {
      display: none;
    }
  }
}
