ul.tr-action-links {
  text-transform: capitalize;

  li {
    float: left;
    margin-right: 20px;

    a {
      padding: 10px 20px;
      border: thin solid $blue;
      border-radius: 3px;
    }
    a:hover {
      background-color: $blue;
      color: white;
    }
  }
}
