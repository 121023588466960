.l-content {
  min-height: 400px;
}

.tr-boxes-view {
  padding: 0 20px 20px 20px;
  .masonry-column {
    width: 100%;
  }
  .views-row {
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    margin: 20px 0 0 0;
    padding: 0;
    width: 100%;
  }
}

/**
 * Tablets.
 */
@include breakpoint($tablet) {
  body {
    background-repeat: repeat;
  }

  /**
   * Boxes views.
   */
  .tr-boxes-view {
    padding: 0 gutter($tab-columns) gutter($tab-columns) gutter($tab-columns);
    .views-row,
    .masonry-column {
      width: span(6 of $tab-columns);
    }
    .views-row {
      margin: gutter($tab-columns) 0 0 0;
    }
    .masonry-gutter {
      width: gutter($tab-columns);
    }
  }
}

/**
 * Desktops.
 */
@include breakpoint($desktop) {
  body {
    background-repeat: repeat-x;
  }

  /**
   * Boxes views.
   */
  .tr-boxes-view {
    .views-row,
    .masonry-column {
      width: span(4 of $tab-columns);
    }
  }
}
