.page-event-register-duplicated,
.page-event-register-limit,
.page-event-register-success {
  background-color: $black-haze;

  .page-title {
    color: $blue;
    margin-top: 30px;
  }
  .breadcrumb {
    display: none;
  }
  .registration-confirmation-message {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    max-width: 600px;
    margin: 20px;
    padding: 20px;
    text-align: center;
  }
  .general-confirmation-message {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
  }
  .event-confirmation-message {
    border-top: thin solid $black-haze;
    margin-top: 1em;
    padding-top: 1em;
  }
  .registration-thanks {
    color: $light-gray;
    font-size: 40px;
    line-height: 40px;
    margin: 30px 0;
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .page-event-register-duplicated,
  .page-event-register-limit,
  .page-event-register-success {
    .page-title {
      font-size: 40px;
      margin: 50px 0;
    }
    .registration-confirmation-message {
      margin: 0 auto;
    }
  }
}
