$blue: #249fe2;
$mercury: #e4e4e4;
$mine-shaft: #333333;
$black-haze: #f8f8f8;
$light-gray: #CCCCCC;
$certificate-gray: #4A4B4C;
$gray: lighten($certificate-gray, 30%);
$wild-sand:#F4F4F4;
$red: #d94f13;
$orange-peel: #ff9c00;
$dark-burgundy: #7c0508;
