.page-confirm-contact {
  .breadcrumb {
    display: none;
  }
  .confirm-page-content {
    font-size: 24px;
    line-height: 30px;
    padding: 50px 0;
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .page-confirm-contact {
    .page-title {
      font-size: 40px;
      margin-top: 100px;
    }
    .confirm-page-content {
      padding: 50px 30%;
    }
  }
}
