.ajax-progress {
  background-color: $blue;
  border: 2px solid white;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 10;
  .throbber {
    background: url('../images/loader.gif');
    margin: 10px;
    height: 60px;
    width: 60px;
  }
}
// Tablet.
@include breakpoint($tablet) {
  .ajax-progress {
    right: 40px;
    top: 40px;
  }
}
