.bean-text-with-image {
  p {
    margin-bottom: 15px;
  }
}
// Tablet.
@include breakpoint($tablet) {
  .bean-text-with-image {
    .group-left {
      @include span(6 of $tab-columns);
    }
    .group-right {
      @include span(6 of $tab-columns last);
    }
  }
}
