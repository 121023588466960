.l-header {
  border-bottom: thin solid rgba(black, 0);
  left: 0;
  position: fixed;
  transition: all 0.5s ease-out;
  top: 0;
  width: 100%;
  z-index: 2;

  .logo {
    background: url("../images/logo.png") no-repeat 0 -40px;
    display: block;
    float: left;
    height: 40px;
    width: 40px;
  }

  // Site name.
  .site-logo {
    padding: 0;
    display: inline-block;

    .site-name {
      border-left: thin solid $mine-shaft;
      color: $blue;
      display: none;
      font-family: 'Carter One', cursive;
      font-size: 30px;
      float: left;
      margin-left: 4px;
      margin-top: 4px;
      padding: 5px 8px;

      span {
        color: $mine-shaft;
        margin-left: -5px;
      }
    }
  }
}
.l-header.header-fixed {
  background-color: rgba(white, 0.95);
  border-bottom: thin solid rgba(black, 0.1);
  padding: 10px 10px 8px 10px;

  .site-logo {
    .site-name {
      display: block;
    }
  }
}


// Tablet.
@include breakpoint($tablet) {
  .l-header {
  }
}
