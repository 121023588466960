$container-width: 1200px;

// Set consistent vertical and horizontal spacing units.
$vert-spacing-unit: 20px;
$horz-spacing-unit: 1em;

// Define Susy grid variables mobile first.
$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after,
  container: $container-width,
  container-position: center,
  columns: 4,
  gutters: 1/4,
  column-width: 4em,
  global-box-sizing: border-box,
  last-flow: to,
);

// Susy Media Layouts @see http://susy.oddbird.net/guides/reference/#ref-media-layouts
$tablet: 44em; // At 44em,
$tab-columns: 12; // use 12 columns.
$desktop: 70em; // At 70em,
