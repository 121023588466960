.block--bean-results-wherever {
  background-image: url("../images/bg-diagonal-blue.png");
  color: white;
  padding: 60px 20px;

  h2 {
    padding: 0 0 50px 0;
  }
  .block-inner-wrap {
    @include container(1000px);
  }
  .field--name-field-block-body {
    text-align: center;
  }
  .field--name-field-image {
    margin-top: 60px;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block--bean-results-wherever {

    h2 {
      font-size: 30px;
      padding: 50px 0 50px 0;
      text-align: right;
    }
    .group-left {
      box-sizing: border-box;
      padding-right: 50px;
      padding-top: 60px;
    }
    .field--name-field-block-body {
      text-align: right;
    }
    .field--name-field-image {
      margin-top: 0;
    }
  }
}
