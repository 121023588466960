.contact-form {
  text-align: center;

  .block-inner-wrap {
    @include container(800px);
  }
  .webform-component--contact-me-at {
    text-align: center;

    label {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    select {
      min-width: 200px;
    }
  }
  .form-actions {
    border-top: thin solid $mercury;
    margin-top: 20px;
    padding-top: 20px;
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .contact-form {
    .webform-component--name {
      @include span(5 of $tab-columns);
    }
    .webform-component--email {
      @include span(4 of $tab-columns);
    }
    .webform-component--phone {
      @include span(3 of $tab-columns last);
    }
    .webform-component--contact-me-at {
      display: inline-block;
      label {
        float: left;
        margin-bottom: 14px;
        margin-right: 20px;
        margin-top: 14px;
      }
    }
    .form-actions {
      margin-top: 70px;
      padding-top: 0;

      input {
        border-left: 20px solid white;
        border-right: 20px solid white;
        position: relative;
        top: -30px;
      }
    }
  }
}
