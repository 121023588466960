input {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

input.form-text {
  border: thin solid $blue;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  width: 100%;

  &.white-border {
    color: white;
    height: 60px;
    background-color: rgba(black, 0.6);
    border: 3px solid white;
    font-size: 22px;
  }

  &.error {
    border: thin solid $red;
  }

  @include breakpoint($tablet) {
    width: auto;
    max-width: 100%;
  }
}
input:focus {
  outline: 0;
}

// Select.
select {
  cursor: pointer;
  z-index: 2;
}
.customSelect {
  height: 50px;
  position: relative;
  z-index: 1;

  .customSelectInner {
    background-color: $blue;
    background-image: url("../images/select-arrow.png");
    background-repeat: no-repeat;
    background-position: center right;
    box-sizing: border-box;
    color: white;
    padding: 14px 50px 14px 14px;
  }

  &.error {
    .customSelectInner {
      background-color: $red;
    }
  }

  &.white-border {
    height: 60px;
    .customSelectInner {
      background-color: rgba(black, 0.6);
      border: 3px solid white;
      font-size: 22px;
      padding: 17px 52px 17px 15px;
    }
  }
}

// Buttons.
.link-button,
input.form-submit {
  background-color: $blue;
  border: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 40px;
  width: 100%;

  &:hover {
    background-color: darken($blue, 20%);
  }

  // Tablet.
  @include breakpoint($tablet) {
    width: auto;
  }
}


html.js {
  input.form-autocomplete {
    background: url("../images/throbber-inactive.png") no-repeat scroll 98% 50% transparent;
  }
  input.form-autocomplete.throbbing {
    background: url("../images/throbber-active.gif") no-repeat scroll 98% 50% transparent;
  }
}