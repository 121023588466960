body {
  background-repeat: no-repeat;
  color: $mine-shaft;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}
h1 {
  color: $blue;
  font-size: 24px;
  line-height: 30px;
}
h2 {
  font-size: 24px;
  line-height: 30px;
}
p {
  padding-top: 5px;
  padding-bottom: 5px;
}
img {
  height: auto;
  max-width: 100%;
}
a {
  color: $blue;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-decoration: none;
}
