.block--bean-huge-experience {
  background-image: url("../images/bg-blizzard.png");
  padding: 60px 20px;

  h2 {
    padding-bottom: 50px;
  }
  .block-inner-wrap {
    @include container;
  }
  .field--name-field-block-body {
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block--bean-huge-experience {
    padding: 80px 20px;

    h2 {
      font-size: 20px;
      text-align: left;
      width: 70%;
    }
    .group-right {
      padding-top: 40px;
    }
    .field--name-field-block-body {
      text-align: left;
    }
  }
}
