.block--bean-wheater-endures {
  background-image: url("../images/bg-halftone.png");
  padding: 60px 20px;

  h2 {
    padding-bottom: 50px;
  }
  .block-inner-wrap {
    @include container(1000px);
  }
  .field--name-field-block-body {
    text-align: center;
  }
}

// Tablet.
@include breakpoint($tablet) {
  .block--bean-wheater-endures {
    position: relative;

    &:after {
      background: url("../images/weather.png") no-repeat;
      content: "";
      display: block;
      height: 281px;
      position: absolute;
      right: 0;
      top: 0;
      width: 309px;
      z-index: 1;
    }
    h2 {
      font-size: 20px;
      padding-bottom: 30px;
      text-align: left;
      width: 70%;
    }
    .group-left {
      @include span(5.5 of $tab-columns);
    }
    .group-right {
      @include span(6 of $tab-columns);
      padding-top: 120px;
    }
    .field--name-field-block-body {
      text-align: left;
    }
  }
}
