table {
  text-align: center;
  width: 100%;

  th {
    background-color: $blue;
    color: white;
    height: 45px;
    font-size: 18px;
    vertical-align: middle;
    text-align: center;
  }
  tr.even {
      background-color: $wild-sand;
  }
  tr.odd {
    background-color: white;
  }
  td {
    height: 35px;
    vertical-align: middle;
  }
  caption {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 26px;
    &::after,
    &::before {
      color: $light-gray;
      content: "-----------------";
      font-weight: 300;
      letter-spacing: -2px;
    }
    &::after {
      margin-left: 20px;
    }
    &::before {
      margin-right: 20px;
    }
  }
}
