.page-event-register,
.page-event-all-register {
  background-color: $black-haze;

  #edit-actions-prev,
  *[id^='edit-paging-header'] {
    display: none;
  }
  .page-title {
    color: $blue;
    margin-top: 50px;
  }
  .breadcrumb {
    display: none;
  }
  .event-registration-form {
    background-color: white;
    max-width: 600px;
    padding: 20px;

    .registration-notes {
      border: thin solid $red;
      margin-bottom: 20px;
      padding: 20px;

      .fieldset-legend {
        color: $red;
        font-size: 20px;
        font-weight: bold;
        padding: 10px;
      }
    }
    .event-registrations-image {
      margin-bottom: 20px;
    }
    .form-type-select {
      position: relative;
      width: 100%;
    }

    select {
      width: 100%;
    }
  }
  #edit-category-finder {
    border-bottom: thin solid $mercury;
    border-top: thin solid $mercury;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px 0 0 0;

    legend {
      text-align: center;

      span {
        background-color: white;
        font-weight: bold;
        margin-top: -30px;
        padding: 10px 20px;
        position: relative;
      }
    }
    #tr-registration-found-category {
      font-size: 22px;
      font-weight: bold;
      float: left;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
    }
  }

  .group-tshirt {
    border-bottom: thin solid $mercury;
    margin-bottom: 20px;

    legend {
      margin-bottom: 20px;
      text-align: center;
      font-weight: bold;
    }
  }

  #block-bean-event-registration-notes {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    span {
      color: $red;
    }
  }
}

// Tablet.
@include breakpoint($tablet) {
  .page-event-register,
  .page-event-all-register {
    .page-title {
      font-size: 40px;
      margin: 50px 0;
    }
    .event-registration-form {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      margin: 0 auto;
      padding: 40px;

      input.form-text {
        width: 100%;
      }
      .form-item {
        margin-bottom: 2em;
      }
      .form-actions {
        border-top: thin solid #e4e4e4;
        height: 30px;
        margin-top: 70px;
        margin-bottom: 0;
        padding-top: 0;
        text-align: center;
      }
      .form-actions input {
        border-left: 20px solid white;
        border-right: 20px solid white;
        position: relative;
        top: -30px;
      }
    }
    #edit-category-finder {
      .form-item-category-finder-year-of-birth input {
        height: 48px;
      }
      .fieldset-wrapper > .form-item {
        @include span(4 of $tab-columns);
      }
      #tr-registration-category-select {
        @include span(4 of $tab-columns last);
      }
    }
    .group-tshirt {
      .field-name-field-add-tshirt { 
        @include span(6 of $tab-columns);
        text-align: center;
        .form-item {
          display: inline-block;
          padding-top: 30px;
        }  
      }
      .field-name-field-size {
        @include span(6 of $tab-columns last);
      }
    }
  }
}
